import { SearchModesType, InputModesType } from '../../reducers/talentSearch';
import { Option } from '../../components/TalentSearch/SearchBar/AutocompleteSearchBar';

export enum ACTIONS {
  UPDATE_TALENTS = 'UPDATE_TALENTS',
  TOGGLE_SEARCH_MODE = 'TALENT_SEARCH/TOGGLE_SEARCH_MODE',
  TOGGLE_INPUT_MODE = 'TALENT_SEARCH/TOGGLE_INPUT_MODE',
  TALENT_SEARCH = 'TALENT_SEARCH/TALENT_SEARCH',
  PROFILES_SEARCH = 'TALENT_SEARCH/PROFILES_SEARCH',
  SET_FILTERS = 'TALENT_SEARCH/SET_FILTERS',
  RESET_FILTERS = 'TALENT_SEARCH/RESET_FILTERS',
  SET_TALENT_PROFILE_FILTERS = 'TALENT_SEARCH/SET_TALENT_PROFILE_FILTERS',
  RESET_TALENT_PROFILE_FILTERS = 'TALENT_SEARCH/RESET_TALENT_PROFILE_FILTERS',
  FETCH_AUTOCOMPLETE = 'TALENT_SEARCH/FETCH_AUTOCOMPLETE',
  FETCH_PROFILE_PICTURE = 'TALENT_SEARCH/FETCH_PROFILE_PICTURE',
  FETCH_EXPLAINABLE_AI_DATA = 'TALENT_SEARCH/FETCH_EXPLAINABLE_AI_DATA',
  SET_PAGE = 'TALENT_SEARCH/SET_PAGE',
  DOWNLOAD_RESULTS = 'TALENT_SEARCH/DOWNLOAD_RESULTS',
}

type TermDetectedCategories = 'COMPETENCYPOOL' | 'COMPETENCY' | 'FUNCTION';

export interface SkillData {
  name: string;
  proficiency: number;
}
export interface Specializations {
  name: string;
  specialtyClassification: string;
  matching?: number;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface PMI_TalentData {
  availability: {
    fromDate: string;
    toDate: string;
    percentage: number;
  };
  cellPhone: string;
  city: string;
  competency: { name: string; proficiency: number }[];
  competencyPool?: { name: string; proficiency: number }[];
  country: string;
  email: string;
  extra: [
    {
      category: string;
      prediction: string[];
      source: string[];
      term: string;
    }
  ];
  firstName: string;
  function: string;
  jobTitle: string;
  lastName: string;
  internalExperience: string[];
  managerEmail: string;
  managerName: string;
  officePhone: string;
  rankPosition: number;
  score: number;
  staffing: [
    {
      fromDate: string;
      toDate: string;
      percentage: number;
    }
  ];
  subFunction?: string;
  supplyKey: number;
  // UNUSED props below
  query: string;
  level: number;
  costUnit: string;
  skillsExtra?: SkillData[];
  proximities?: SkillData[];
  jobExperience?: {
    company: string;
  }[];
}

export interface TalentSearchPayload {
  bias: {
    KS: {
      ES?: number;
      F?: number;
      M?: number;
      X?: number;
      d?: number;
      pval?: number;
    };
    control: number[];
    data: number[];
  };
  difficulty: number;
  onlyShowAvailableEmployees: boolean;
  processingTime: number;
  query: {
    freeText: boolean;
    raw: string;
    autocorrect: string;
    searchMode: string;
  };
  rankingMax?: number;
  rankingMin?: number;
  items?: PMI_TalentData[];
  serverMessages: {
    message: string;
    type: string;
  }[];
  searchResultsLimit: number;
  searchResultsOffset: number;
  skillsDetected?: {
    foundAtCharacter: number;
    proficiency: number;
    skill: string;
  }[];
  termsDetected?: {
    term: string;
    foundAtCharacter: number;
    category: TermDetectedCategories;
    ambiguity?: number;
    predictions?: string[];
  }[];
  referenceUser?: PMI_TalentData[];
}

export interface FetchProfilePicturePayload {
  enterpriseId: string;
  profilePicture: string;
}

export interface ToggleSearchMode {
  type: ACTIONS.TOGGLE_SEARCH_MODE;
  payload: SearchModesType;
}

export interface ToggleInputMode {
  type: ACTIONS.TOGGLE_INPUT_MODE;
}

export interface TalentSearch {
  type: ACTIONS.TALENT_SEARCH;
  payload: TalentSearchPayload;
  searchMode: SearchModesType;
  inputMode: InputModesType;
  autocompleteValue: Option;
}

export interface ProfilesSearch {
  type: ACTIONS.PROFILES_SEARCH;
  payload: TalentSearchPayload;
}

export interface TalentSearchQueryString {
  [key: string]:
    | string
    | boolean
    | undefined
    | SearchFilters['active']
    | SearchFilters['availabilitypercent']
    | SearchFilters['dateTo']
    | SearchFilters['location']
    | SearchFilters['location'];
  query?: string;
  freeTextQuery?: boolean;
  searchMode?: string;
  active: Partial<SearchFilters['active']>;
  availabilitypercent: number | number[];
  dateTo: Date | null;
  dateFrom: Date | null;
  location: string[];
  function: string[];
}

export interface FetchProfilePicture {
  type: ACTIONS.FETCH_PROFILE_PICTURE;
  payload: FetchProfilePicturePayload;
}

// export interface ExplainableAIPayload {
//   PrflKey: number;
//   boolean: number;
//   cosine: {
//     name: string;
//     rank_important: number;
//     skill_occurrence: number;
//     skill_predicted: boolean;
//     str_overlap: number;
//     'text in cv': string[];
//     'text in project': string[];
//     tf_score: {
//       certificate: number;
//       cv: number;
//       education: number;
//       gsl: number;
//       project: number;
//       training: number;
//     };
//   }[];
//   max_score: number;
//   query_normalized: string;
//   query_raw: string;
//   rank: number;
//   raw_score: number;
// }

export interface ExplainableAIPayload {
  peopleKey: number;
  data: {
    extractedData: {
      skill: string;
      term: string;
      percentMatch: number;
      rank: number;
      exact: boolean;
      occurence: number;
      tfValue: {
        competency?: number;
        competencyPool?: number;
        managerReview?: number;
        selfReview?: number;
        strengths?: number;
        internalRoleDetails?: number;
        externalRoleDetails?: number;
        internalExperience?: number;
        externalExperience?: number;
        jobTitle?: number;
        roleName?: number;
      };
    }[];
  };
}

export interface FetchExplainableAIData {
  type: ACTIONS.FETCH_EXPLAINABLE_AI_DATA;
  payload: {
    supplyKey: PMI_TalentData['supplyKey'];
    data: ExplainableAIPayload;
  };
}
export enum AutocompleteTypes {
  competency = 'competency',
  userNames = 'userNames',
  rawUserNames = 'rawUserNames',
  competences = 'competences',
  level = 'level',
  locations = 'locations',
  offices = 'offices',
  dtes = 'dtes',
  languages = 'languages',
  employees = 'employees',
  skills = 'skills',
  industries = 'industries',
  delegation_users = 'delegation_users',
  request_keys = 'request_keys',
  client_names = 'client_names',
  role_names = 'role_names',
  primary_contacts = 'primary_contacts',
  functions = 'functions',
  subFunctions = 'subFunctions',
}
export interface CVProps {
  [key: string]: {
    file_ext: string;
    uploaded_at: string;
    uploaded_by: string;
  };
}
export interface MetadataPayload {
  pagination?: {
    currentPage: number;
    limit: number;
    nextOffset: number;
    offset: number;
    pageCount: number;
    previousOffset: null;
    totalCount: number;
  };
  serverMessages: string[];
  tracking?: {
    indexer: {
      competency: string[];
    };
    matcher?: {
      competency: string[];
      competencyPool: string[];
    };
    mapper?: {};
    fuzzier?: {
      competency: string[];
    };
  };
}
export type FetchAutocompletePayload = {
  items: string[];
  metadata: MetadataPayload;
};

export interface FetchAutocomplete {
  type: ACTIONS.FETCH_AUTOCOMPLETE;
  payload: {
    subjectMatter: AutocompleteTypes;
    data: FetchAutocompletePayload;
  };
}

export interface SearchFilters {
  active: Partial<SearchFilters>;
  availabilitypercent: number | number[];
  dateTo: Date | null;
  dateFrom: Date | null;
  location: string[];
  function: string[];
  subFunction: string[];
}

export interface SetTalentSearchFilters {
  type: ACTIONS.SET_FILTERS;
  payload: SearchFilters;
}

export interface ResetTalentSearchFilters {
  type: ACTIONS.RESET_FILTERS;
  payload: SearchFilters;
}

export interface SetTalentProfileFilters {
  type: ACTIONS.SET_TALENT_PROFILE_FILTERS;
  payload: {
    name: string;
  };
}

export interface ResetTalentProfileFilters {
  type: ACTIONS.RESET_TALENT_PROFILE_FILTERS;
  payload: {
    name: null;
  };
}

export interface SetPage {
  type: ACTIONS.SET_PAGE;
  payload: number;
}

export interface UpdateTalents {
  type: ACTIONS.UPDATE_TALENTS;
  payload: any;
}

export type TalentSearchActions =
  | UpdateTalents
  | ToggleSearchMode
  | ToggleInputMode
  | TalentSearch
  | ProfilesSearch
  | FetchAutocomplete
  | FetchProfilePicture
  | SetTalentSearchFilters
  | ResetTalentSearchFilters
  | SetTalentProfileFilters
  | ResetTalentProfileFilters
  | FetchExplainableAIData
  | SetPage;
  